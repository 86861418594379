import review1Img from '../../../assets/img/review1.png'
import review2Img from '../../../assets/img/review2.png'
import review3Img from '../../../assets/img/review3.jpeg'

import style from './style.module.css'


const Reviews = ({
  title,
   subtitle
}) => {
  return (
    <section className={`black-section ${style.wrap}`}>
      <div className="row black_row">
        <div className="col-lg-6 col-md-6">
          <h2 dangerouslySetInnerHTML={{__html: title}} />
          <h6 className={style['review-title']}>Ann, designer (Turkey camp)</h6>
          <p>{subtitle}</p>

          <ul className={`list-inline social social-sm ${style['list-wrap']}`}>
            <li className="list-inline-item">
              <a target="_blank" href="https://bit.ly/3oxvlKS"><i className={`lni lni-facebook mr-2 ${style['list-icon']}`} /></a>
            </li>
            <li className="list-inline-item">
              <a target="_blank" href="https://bit.ly/37TDcwE"><i className={`lni lni-twitter mr-2 ${style['list-icon']}`} /></a>
            </li>
            <li className="list-inline-item">
              <a target="_blank" href="https://bit.ly/3kF7bf3"><i className={`lni lni-instagram mr-2 ${style['list-icon']}`} /></a>
            </li>
            <li className="list-inline-item">
              <a target="_blank" href="https://bit.ly/31Ul8P2"><i className={`lni lni-vk mr-2 ${style['list-icon']}`}/></a>
            </li>
          </ul>
          <br />
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="row">
            <div className="col-lg-12">
              <img src={review1Img} alt="remotecamp_photo" className="img-responsive images" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export {
  Reviews
}
