import style from './style.module.css'

const TimeToTravelCard = ({
  subtitle,
  title,
  text,
  link
}) => {
  return (
    <div className={`each_camp ${style['each_camp']}`}>
      <div className={`feature-area ${style['feature-area']}`}>
        <div className={`card_panel ${style['card_panel']}`}>
          <div className={style['card-content']}>
            {subtitle && (
              <p className={style['text-item']}>{subtitle}</p>
            )}
            {title && (
              <h6 className={style['title']}>
                {title}
              </h6>
            )}
            {text && (
              <p className={style['text-item']}>{text}</p>
            )}
          </div>
          <div className={`camp_text ${style['camp_text']}`}>
            {link && (
              <div className={`header-btn ${style['header-btn']}`}>
                <a className={`btn-hover ${style['btn-hover']}`} target="_blank" href={link.url}>{link.title}</a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export {
  TimeToTravelCard
}
