import { useState } from "react";

import style from './style.module.css'

const PollCard = (
  {
    title,
    question,
    pollItems
  }
) => {
  const [pollComplete, setPollComplete] = useState(false);

  return (
    <div className={`feature-area ${style['feature-area']}`}>
      <div className={`card_panel poll_card ${style['poll_card']}`}>
        <div className="camp_text tc">
          {title && (
            <h6 className={style.title}>{title}</h6>
          )}
          {pollItems?.length > 0 && (
            <>
              <p className={style.question} dangerouslySetInnerHTML={{__html: question}}/>
              <div id="pollContainer">
                <div id="pollButtons">
                  <ul>
                    {pollItems.map((poll) => {
                      return (
                        <li
                          onClick={() => {setPollComplete(true)}}
                          className="btn-hover"
                          style={{
                            backgroundImage: pollComplete ? `linear-gradient(90deg, rgb(242, 87, 87) 7%, transparent ${poll.successPercent}%)` : null,
                          }}
                          key={poll.option}>{poll.option}</li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
)
}

export {
  PollCard
}
