import style from './style.module.css'
import { DATA_EN, DATA_RU } from '../../assets/data/index'

import { Hero } from "./Hero";
import { Steps } from "./Steps";
import { About } from "./About";
import { Logos } from "./Logos";
import { LearnMore } from "./LearnMore";
import { Cards } from "./Cards";
import {Reviews} from "./Reviews";

const BLOCK_BY_TYPES = {
  hero: Hero,
  steps: Steps,
  about: About,
  logos: Logos,
  learnMore: LearnMore,
  cards: Cards,
  reviews: Reviews,
}

const Main = () => {

  const dataSource = window.location.href.includes('/en/') ? DATA_EN : DATA_RU
  return (
    <>
      {dataSource.map((block, i) => {
        const Block = BLOCK_BY_TYPES[block.type]

        if (!BLOCK_BY_TYPES[block.type]) {
          return null
        }

        return  (
          <Block {...block.data} key={i}/>
        )
      })}
    </>
  )
}

export {
  Main
}
