import { useEffect, useState } from "react";
import style from './style.module.css'

const BottomMenu = ({
                      list,
                      link
}) => {

    const [activeItem, setActiveItem] = useState(null);

    useEffect(() => {
        function onScroll() {
            let currentPosition = window.pageYOffset;
            const blocks = {}
            list.forEach((listItem) => {
                blocks[listItem.link] = document.getElementById(listItem.link).offsetTop
            })
            let activeItem = null
             Object.entries(blocks).forEach(([key, value]) => {
                 if (currentPosition > value) {
                     activeItem = key
                 }
            })
            setActiveItem(activeItem)
        }

        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    }, []);


  return (
    <section className={`container ${style['container']}`}>
      <div className={`row ${style['row']}`} id="bottom_menu">
        {list.map((listItem) => {
          return (
            <a className={`price_btn menu_btn ${listItem.link === activeItem ? 'active': ''}`}
               href={`#${listItem.link}`} key={listItem.link}>
              <i className={`lni lni-${listItem.icon}`} />
              {listItem.withNotification && (
                <span className={style['notification']}/>
              )}
              <p className={`hidden-web ${style.title}`}>{listItem.title}</p>
              <p className={`hidden ${style.text}`}>{listItem.text}</p>
            </a>
          )
        })}

        <a className="main-btn menu_btn active apply_btn" href={link.url}>
          <i className="lni lni-checkmark-circle mr-2"> </i>
          <p className={`hidden-web ${style['apply-btn']}`}>{link.text}</p>
          <span className="hidden">&nbsp; &nbsp; &nbsp; {link.text} &nbsp; &nbsp; &nbsp;</span>
        </a>
      </div>
    </section>
  )
}

export {
  BottomMenu
}
