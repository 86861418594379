import style from './style.module.css'
import {Fragment} from "react";

const Hero = ({
  backgroundImg,
  location,
  description,
  backLink,
  nextLink,
  price,
  intro,
  form,
  paymentDetails,
}) => {

  const totalSum = paymentDetails.list.reduce((acc, curr) => {
    return acc + parseInt(curr.price, 10)
  }, 0)
  return (
    <section id="home" className={`hero-area camp_intro ${style['camp_intro']}`} style={{
      backgroundImage: `url(${backgroundImg})`
    }}>
      <div className="container">
        {backLink && (
          <label className={`control control_arrow ${style['nav-link']} ${style['nav-link--back']}`}>
            <a href={backLink.url}>
              <span className={`control__content ${style['control']} ${style['control--prev']}`}>
                <svg aria-hidden="true" focusable="false" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <circle cx="15" cy="15" r="15" fill="#1E1B1D" />
                  <path
                    d="M0 12c0 6.627 5.373 12 12 12s12-5.373 12-12-5.373-12-12-12-12 5.373-12 12zm7.58 0l5.988-5.995 1.414 1.416-4.574 4.579 4.574 4.59-1.414 1.416-5.988-6.006z"
                    fill="#fff"/>
                </svg>
                <span className="arrow_text">&nbsp; {backLink.text} &nbsp;</span>
              </span>
            </a>
          </label>
        )}
        {nextLink && (
          <label className={`control control_arrow ${style['nav-link']} ${style['nav-link--next']}`}>
            <a href={nextLink.url}>
              <span className={`control__content ${style['control']} ${style['control--next']}`}>
                <span className="arrow_text">&nbsp; {nextLink.text} &nbsp;</span>
                <svg aria-hidden="true" focusable="false" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <circle cx="15" cy="15" r="15" fill="#1E1B1D" />
                <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.218 19l-1.782-1.75 5.25-5.25-5.25-5.25 1.782-1.75 6.968 7-6.968 7z" fill="#fff"/>
              </svg>
              </span>
            </a>
          </label>
        )}
        <div className="row align-items-center">
          <div className="col-xl-4 col-lg-4">
            <div className="feature-area" id="cost">
              <div className={`card_panel price_panel ${style['panel']}`}>
                <div>
                  <h6 className={`${style.title}`}>
                    {price.value} <span className={`${style['price-unit']}`}>/ ${price.unit}</span></h6>
                  <span className={`${style['price-description']}`}>{price.description}</span>
                  <a href={intro.url}
                     className={style['intro-link']}>
                    <i className="lni lni-video" />
                    {intro.text}
                  </a>
                </div>

                <div className={`row ${style['form-wrap']}`}>
                  <div className="multi-button">
                    <button>
                      <span>{form.checkIn.title}</span>
                      <p>{form.checkIn.placeholder}</p>
                    </button>
                    <button >
                      <span>{form.checkOut.title}</span>
                      <p>{form.checkOut.placeholder}</p>
                    </button>
                  </div>
                  <div className={`dropdown ${style['dropdown']}`}>
                              <span className={`${style['dropdown-label']}`}>Room</span>
                    <select className={`btn btn-default dropdown-toggle ${style['dropdown-btn']}`}>
                      {form.dropdown.map((item) => {
                        return (
                          <option value={item.val} key={item.val}>
                            {item.legend}
                          </option>
                        )
                      })}
                    </select>
                  </div>

                </div>
                <div className="row">
                  <div className="header-btn">
                    <a className={`main-btn btn-hover ${style['main-btn']}`} href={form.submit.url}>{form.submit.text}</a>
                  </div>
                  <p className={style['submit-legend']}>{form.submit.legend}</p>
                </div>
                <div className="row">
                  <ul className="list-unstyled row">
                    {paymentDetails.list.map((item, i) => {
                      return (
                        <Fragment key={i}>
                          <li className={`list-item col-6 py-2 ${style['list-item']} ${style['list-item--text']}`} ><p
                            className="tooltip-toggle" tabIndex="0"
                            aria-label={item.ariaLabel}>
                            {item.text}</p>
                          </li>
                          <li className={`list-item col-6 py-2 ${style['list-item']} ${style['list-item--price']}`}>
                            <span>{item.price} {paymentDetails.currency}</span>
                          </li>
                        </Fragment>
                      )
                    })}
                    <li className={`list-item col-6 py-2 ${style['list-item']} ${style['list-item--text']} ${style['applyLink.iconne']}`} ><p>
                      <strong>
                        {paymentDetails.totalText}
                      </strong>
                    </p></li>
                    <li className={`list-item col-6 py-2 ${style['list-item']} ${style['list-item--price']}`}><span>
                      <strong>
                        {totalSum}{paymentDetails.currency}
                      </strong>
                    </span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8 order-first order-md-last">
            {location && (
              <h2 className="main_title_camp"> {location}</h2>
            )}
            {description && (
              <div>
                <p className={`${style['description']}`}>
                  {description}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export {
  Hero
}
