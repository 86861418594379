import style from './style.module.css'

const Date = ({
 title,
 date,
                text,
                applyLink,
  calendar
}) => {
  return (
    <section className={`white-section container ${style['wrap']}`} id="when">
      <div className="black_row row">
        <div className="col-lg-6">

          {calendar && (
            <div className="calendar">
              <header>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M15.375 5.25L8.625 12L15.375 18.75" stroke="black" strokeWidth="1"/>
                </svg>

                <span>{calendar.title}</span>

                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M8.625 18.75L15.375 12L8.625 5.25" stroke="black" strokeWidth="1"/>
                </svg>

              </header>
              <div className="days">
                {calendar.daysLabels.map((d, i) => {
                  return (
                    <span key={i}>{d}</span>
                  )
                })}
              </div>
              <div className="dates">
                {calendar.dates.map((dateRow, i) => {
                  return (
                    <div className="flex" key={i}>
                      {dateRow.map((dateRowItem) => {
                        return (
                          <span className={dateRowItem.className} key={dateRowItem.val}>{dateRowItem.val}</span>
                        )
                      })}
                    </div>
                  )
                })}
              </div>
            </div>
          )}

        </div>
        <div className="col-lg-6 order-first">
          <h2>{title}</h2>
          <h6 className={`camp_date ${style['camp_date']}`}>
            {date}
          </h6>
          <div dangerouslySetInnerHTML={{__html: text}} />
        </div>
      </div>
      <div className={`row ${style['apply-row']}`}>
        <a className={`main-btn btn-hover ${style['main-btn']}`} href={applyLink.url}
        >
          {applyLink.text} <i className={`lni mr-2 ${applyLink.icon}`} />
        </a>
      </div>
    </section>
  )
}

export {
  Date
}
