const Gallery = ({title, gallery}) => {
  return (
    <section className="white-section container" id="where">
      <div className="row black_row">
        {title && (
          <div className="col-lg-12">
            <h2>
              {title}
            </h2>
          </div>
        )}
      </div>
      <div className="row">
        <div className="slider">
          {gallery.map((galleryItem, i) => {
            return (
              <div className="slide slide_where pop" key={i}>
                <img src={galleryItem.img} className="img " alt="" />
                <br />
                <p className="hidden">{galleryItem.description}</p>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}
export {
  Gallery
}
