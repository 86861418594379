import style from './style.module.css'

const Program = ({title, text, img}) => {
  return (
    <section className="white-section container" id="program">
      {title && (
        <div className="black_row row">
          <div className="col-sm-12">
            <div className="row">
              <div className="col-lg-12">
                <h2>{title}</h2>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="row">
        {img && (
          <div className="col-lg-6 col-hidden">
            <img className={style.img} src={img} width="530px" height="550px" />
          </div>
        )}
        {text && (
          <div className="col-lg-6">
            <div dangerouslySetInnerHTML={{__html: text}}/>
          </div>
        )}
      </div>
    </section>
  )
}

export {
  Program
}
