const About = ({title, text, gallery}) => {
  return (
    <section className="white-section container">
      <div className="row black_row">
        <div className="col-lg-12">
          {title && (
            <h2>
              {title}
            </h2>
          )}
        </div>
      </div>
      <div className="row">
        {text && (
          <div className="col-lg-6">
            <div dangerouslySetInnerHTML={{ __html: text }}/>
          </div>
        )}
        {gallery && (
          <div className="col-lg-6">
            <div className="grid">
              <div className="panel panel-title" />
              {gallery.map((item, i) => {

                if (item.text) {
                  return (
                    <div className={`panel panel-${i + 1}`} >
                      <p>
                        {item.text}
                      </p>
                    </div>
                  )
                }

                return (
                  <div style={{
                    backgroundImage: `url(${item.img})`
                  }} className={`panel panel-${i + 1}`} />
                )
              })}
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

export {
  About
}
