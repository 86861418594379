import { Link } from "react-router-dom";
import {ROUTES_CONFIG } from '../../utils/routes'
import { Subscribe } from '../../components/Subscribe'

import style from './style.module.css'

import bulgaria from '../../assets/img/partners/bulgaria.jpg'
import macedonia from '../../assets/img/partners/macedonia.jpg'
import albania from '../../assets/img/partners/albania.jpg'
import egypt from '../../assets/img/partners/egypt.jpg'
import morocco from '../../assets/img/partners/morocco.jpg'
import italy from '../../assets/img/partners/italy.jpg'
import brazil from '../../assets/img/partners/brazil.jpg'

const PARTNERS_DATA = [
  {
    sectionTitle: 'Current partners',
    partners: [
      {
        category: 'tech',
        img: bulgaria,
        country: 'Bulgaria',
        campTitle: 'Besco',
        url: 'https://besco.bg/'
      },
      {
        category: 'COWORKING',
        img: macedonia,
        country: 'Macedonia',
        campTitle: 'CoWorkOhrid',
        url: 'https://www.coworkohrid.com/'
      },
      {
        category: 'TECH',
        img: albania,
        country: 'Albania',
        campTitle: 'AlbaniaTech',
        url: 'https://albaniatech.org/'
      },
      {
        category: 'TECH',
        img: egypt,
        country: 'Egypt',
        campTitle: 'Startup House of Cairo',
        url: 'http://startuphaus.org/'
      },
      {
        category: 'COLIVING',
        img: bulgaria,
        country: 'Bulgaria',
        campTitle: 'Coliving Burgas',
        url: '#'
      },
      {
        category: 'COLIVING',
        img: macedonia,
        country: 'Macedonia',
        campTitle: 'Coliving Ohrid',
        url: '#'
      }
    ]
  }, {
    sectionTitle: 'Looking for colivings partners',
    partners: [
      {
        category: 'COLIVING',
        img: morocco,
        country: 'Morocco',
        campTitle: 'empty',
        to: `/${ROUTES_CONFIG.partnersForm.route}`,
        btnText: 'Become a partner'
      },
      {
        category: 'COLIVING',
        img: italy,
        country: 'Italy',
        campTitle: 'empty',
        to: `/${ROUTES_CONFIG.partnersForm.route}`,
        btnText: 'Become a partner'
      }]
  },
  {
    sectionTitle: 'Looking for coworkings partners',
    partners: [
      {
        category: 'COLIVING',
        img: morocco,
        country: 'Morocco',
        campTitle: 'empty',
        to: `/${ROUTES_CONFIG.partnersForm.route}`,
        btnText: 'Become a partner'
      },
      {
        category: 'COLIVING',
        img: italy,
        country: 'Italy',
        campTitle: 'empty',
        to: `/${ROUTES_CONFIG.partnersForm.route}`,
        btnText: 'Become a partner'
      }
    ]
  }, {
    sectionTitle: 'Looking for startup ecosystems',
    partners: [
      {
        category: 'COLIVING',
        img: morocco,
        country: 'Morocco',
        campTitle: 'empty',
        to: `/${ROUTES_CONFIG.partnersForm.route}`,
        btnText: 'Become a partner'
      },
      {
        category: 'COLIVING',
        img: italy,
        country: 'Italy',
        campTitle: 'empty',
        to: `/${ROUTES_CONFIG.partnersForm.route}`,
        btnText: 'Become a partner'
      },
      {
        category: 'COLIVING',
        img: brazil,
        country: 'Brazil',
        campTitle: 'empty',
        to: `/${ROUTES_CONFIG.partnersForm.route}`,
        btnText: 'Become a partner'
      }
    ]
  }
]



const Partners = () => {
  return (
    <>
      <section className={`tags ${style['hero']}`} id="tags" >
        <div className={`container ${style['container']}`}>
          <div className="row">
            <div className="col-lg-12 col-xs-12">
              <h2 className={style['hero__title']}>Became our partner</h2>
            </div>
          </div>
          <div className="row tc">
            <div className="col-sm-12 text-center">
              <div className={`header-btn ${style['header-btn']}`}>
                <Link to={`/${ROUTES_CONFIG.partnersForm.route}`} className={`main-btn btn-hover ${style['form-btn']}`}>
                  <i className="lni lni-whatsapp mr-2" /> Fill up the form
                </Link>
              </div>
              <div className={`header-btn ${style['header-btn']}`}>
                <a href="https://go.remotecamp.net/3mjMqu" className={`main-btn btn-hover ${style['whatsapp-btn']}`}>
                  <i className="lni lni-whatsapp mr-2" /> Shedule a call
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="white-section container" >

      </section>

      <Subscribe />
    </>
  )
}

export {
  Partners
}
