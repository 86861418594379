import './assets/styles/main.css'

import {
  CampDetail,
  Main,
} from './pages'

import {
  Footer,
  Header,
} from './layout'

const App = () => {
  return (
    <AppContent />
  );
}

const AppContent = () => {

  if (window.location.href.includes('/camps/')) {
    return (
          <CampDetail />
    )
  }

  return (
    <div>
        <Main />
    </div>
  )
}

export default App;
