import {useMemo} from "react";
import { CAMPS } from '../../../assets/data/camps'
import { Gallery } from './Gallery';
import { Faq } from "./Faq";
import { Program } from "./Program";
import {LearnMore} from "../../Main/LearnMore";
import { Activities } from './Activities';
import { About } from './About';
import { Date } from './Date';
import { Hero } from './Hero';
import {BottomMenu} from "./BottomMenu";

const BLOCK_BY_TYPES = {
  faq: Faq,
  learnMore: LearnMore,
  gallery: Gallery,
  program: Program,
  activities: Activities,
  about: About,
  date: Date,
  hero: Hero,
  bottomMenu: BottomMenu,
}

const CampDetail = () => {
  const dataSource = useMemo(() => {
    const splittedPath = window.location.pathname.split('/')
    const locale = window.location.href.includes('/en/') ? 'EN' : 'RU'
    const locationWithExt = splittedPath[splittedPath.length - 1]
    const dataKey = locationWithExt.split('.')[0]
    return CAMPS[dataKey][locale]

  }, [])


  return (
    <div>
      {dataSource.map((block, i) => {
        const Block = BLOCK_BY_TYPES[block.type]

        if (!BLOCK_BY_TYPES[block.type]) {
          return null
        }

        return  (
          <Block {...block.data} key={i}/>
        )
      })}
    </div>
  )
}

export {
  CampDetail
}
