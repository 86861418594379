import albania0 from "../img/camps/albania/0.jpg";
import albania1 from "../img/camps/albania/1.jpg";
import albania2 from "../img/camps/albania/2.jpg";
import albania3 from "../img/camps/albania/3.jpg";
import albania4 from "../img/camps/albania/4.jpg";
import albania5 from "../img/camps/albania/5.jpg";
import albania6 from "../img/camps/albania/6.jpg";

import serbia0 from '../img/camps/serbia/0.jpg'
import serbia1 from '../img/camps/serbia/1.jpg'
import serbia2 from '../img/camps/serbia/2.jpg'
import serbia3 from '../img/camps/serbia/3.jpg'
import serbia4 from '../img/camps/serbia/4.jpg'
import serbia5 from '../img/camps/serbia/5.jpg'
import serbia6 from '../img/camps/serbia/6.jpg'

import africa0 from '../img/camps/long/africa.jpeg'
import long0 from '../img/camps/long/111.jpeg'

const DATA_RU = [
  {

  }
]


const DATA_EN = [
  {
    type: 'hero',
    data: {
      title: 'Work, Live and Travel together',
      tooltip: 'We help to organize travel while you work with right people, we dont provide remote job!',
      closestCampTitle: 'Closest camps ',
      closestCamps: [
        {
          link: 'en/camps/morocco.html',
          title: 'Morocco'
        },
        {
          link: 'en/camps/egypt.html',
          title: 'Egypt'
        },
        {
          link: 'en/camps/turkey.html',
          title: 'Turkey'
        },
        {
          link: 'en/camps/brazil.html',
          title: 'Brazil'
        }
      ],
      features: [
        `<span>👨‍💻
          <a target="_blank" href="https://go.remotecamp.net/9VIgzX">
            <span>find remote job with our partners</span></a><br/>
          </span>`,
        `<span>✔ don't feel yourself alone</span>`,
        `<span>✔ be productive with a group</span>`,
        `<span>✔ do what you love</span>`,
        `<span>✔ travel anywhere</span>`,
      ]
    }
  },
  {
    type: 'steps',
    data: {
      title: 'how does it work ?',
      steps: [
        {
          link: 'en/camps.html',
          text: 'choose the',
          highlight: 'date'
        },
        {
          link: '#',
          text: 'find a',
          highlight: 'program'
        },
        {
          link: '#',
          text: 'find a',
          highlight: 'program'
        },
        {
          link: 'en/about.html',
          text: 'make a',
          highlight: 'payment'
        },
        {
          link: 'en/form.html',
          text: 'travel to',
          highlight: 'camp'
        }
      ]
    }
  },
  {
    type: 'cards',
    data: {
      title: 'Short camps',
      link: {
        title: 'view all >',
        url: 'en/camps.html'
      },
      cards: [
        {
          controlContent: '1 month',
          images: [
            albania0,
            albania1,
            albania2,
            albania3,
            albania4,
            albania5,
            albania6,
          ],
          city: 'Albania, Vlora',
          campText: '14 days camp',
          date: 'September',
          url: '/en/camps/albania',
          price: '35'
        },
        {
          controlContent: 'left 12 places',
          images: [
            albania0,
            albania1,
            albania2,
            albania3,
            albania4,
            albania5,
            albania6,
          ],
          city: 'Albania, Vlora',
          campText: '14 days camp',
          date: 'October',
          url: '/en/camps/albania',
          price: '35'
        },
        {
          controlContent: 'left 12 places',
          images: [
            serbia0,
            serbia1,
            serbia2,
            serbia3,
            serbia4,
            serbia5,
            serbia6,
          ],
          city: 'Serbia, Belgrade',
          campText: '14 days camp',
          date: 'November',
          url: '/en/camps/serbia',
          price: '35'
        },
        {
          type: 'pollCard',
          title: 'What next?',
          question: 'Choose where would you<br />like to go next',
          pollItems: [
            {
              option: 'Asia',
              successPercent: '7'
            },
            {
              option: 'N.America',
              successPercent: '18'
            },
            {
              option: 'S.America',
              successPercent: '33'
            },
            {
              option: 'Africa',
              successPercent: '1'
            },
            {
              option: 'Europe',
              successPercent: '15'
            },
            {
              option: 'Australia',
              successPercent: '25'
            }
          ]
        },
        {
          controlContent: 'left 12 places',
          images: [
            albania0,
            albania1,
            albania2,
            albania3,
            albania4,
            albania5,
            albania6,
          ],
          city: 'Albania, Vlora',
          campText: '14 days camp',
          date: 'August 27 - Sept 10',
          url: '/en/camps/albania',
          price: '35'
        },
        {
          controlContent: 'left 12 places',
          images: [
            serbia0,
            serbia1,
            serbia2,
            serbia3,
            serbia4,
            serbia5,
            serbia6,
          ],
          city: 'Serbia, Belgrade',
          campText: '14 days camp',
          date: 'November',
          url: '/en/camps/serbia',
          price: '35'
        },
      ]
    }
  },
  {
    type: 'about',
    data: {
      text: `
      <p class="text_intro2">We bring together a community of remote workers <b> from 2 weeks to one month</b> to work and explore the world at the same time. Combining work and travel is a lifestyle that has existed for a long time.
            <br/>
              <br/>
          </p>
          <p class="text_intro">
            There are already digital people living and working in different parts of the world and we can help you don't worry about lonelyness and find right people for your new experience.
            <br/>
              <br/>
          </p>
          <p>
            RemoteCamp is a villa in selected locations with all accommodations to <b>comfortable live</b> and <b>work effectively</b>.<br/>We carefully chose everything you need and create comfortable working conditions.
          </p>
      
      `
    }
  },
  {
    type: 'cards',
    data: {
      title: 'Long camps',
      link: {
        title: 'view all >',
        url: 'en/camps.html'
      },
      cards: [
        {
          active: true,
          highlight: true,
          controlContent: '12 months',
          images: [
            africa0
          ],
          campText: '1 year program',
          date: 'Dec 2021 - Dec 2022',
          url: '/en/camps/serbia.html',
          price: '35'
        },
        {
          highlight: true,
          controlContent: '4 months',
          images: [
            long0
          ],
          campText: 'Latin America',
          date: 'Dec 2021 - Mar 2022',
          url: '/en/camps/albania.html',
          price: '35'
        },
        {
          highlight: true,
          controlContent: '4 months',
          campText: 'Europe',
          date: 'Jun 2021 - Sept 10',
          url: '/en/camps/albania.html',
          price: '35'
        },
        {
          type: 'timeToTravel',
          subtitle: 'Through September',
          title: 'The time to travel',
          text: 'Save up to 300$ and lock in 2021 prices on 2022 camps!',
          link: {
            title: 'Find your camp',
            url: 'en/camps.html'
          }
        }
      ]
    }
  },
  {
    type: 'reviews',
    data: {
      title: 'Reviews about <br /> previous camps',
      subtitle: 'I was a participant in the first camp in Turkey, and I want to share my impressions here ✨ This trip became a retreat for me, the people who came to the camp with me turned out to be interesting and really cool 😍 There were problems with the Internet on the first day, but they were quickly resolved. These two weeks of working side by side with enthusiastic people have been a good life experience and I would love to go with Remotecamp on their next journey! 🔥'
    }
  },
  {
    type: 'logos',
    data: {
      title: 'MEDIA'
    }
  },
  {
    type: 'learnMore',
    data: {
      title: 'Do you want to learn more about our camps?',
      followers: {
        title: 'followers',
        count: '1300'
      },
      posts: {
        title: 'posts',
        count: '200',
        text: 'Apply'
      }
    }
  }
]


export {
  DATA_EN,
  DATA_RU
}
