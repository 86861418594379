import style from './style.module.css'

const Steps = ({title, steps}) => {
  return (
    <section className="white-section container">
      <div className="container">
        <h2 className={style.title}>{title}</h2>
        {steps?.length > 0 && (
          <div className="row">
            <form className="col-md-12">
              <fieldset className="tc">
                {steps.map((step, i) => {
                  return (
                    <a href={step.link} className="learn-more button" key={i}>
                        <span className="circle" aria-hidden="true">
                          <span className="icon arrow" />
                        </span>
                      <span className="button-text">{i + 1}. {step.text} <b className={style['highlight']}>{step.highlight}</b></span>
                    </a>
                  )
                })}
              </fieldset>
            </form>
          </div>
        )}
      </div>
    </section>
  )
}

export {
  Steps
}
