import albania0 from "../img/camps/albania/villa/1.jpg";
import albania1 from "../img/camps/albania/villa/2.jpg";
import albania2 from "../img/camps/albania/villa/3.jpg";
import albania3 from "../img/camps/albania/villa/4.jpg";
import albania4 from "../img/camps/albania/villa/5.jpg";
import albania5 from "../img/camps/albania/villa/6.jpg";
import albania6 from "../img/camps/albania/villa/7.jpg";

import albaniaBg from "../img/camps/albania.jpg"


import palmTree from "../img/icons/palm-tree.svg";
import laptop from "../img/icons/laptop.png";
import program from "../img/program.png";
import spot1 from "../img/spot1.jpg";

const CAMPS = {
  albania: {
    RU: [
      {
        ru: true
      }
    ],
    EN: [
      {
        type: 'hero',
        data: {
          backgroundImg: albaniaBg,
          location: 'ALBANIA, SARANDA',
          description: 'Unexpected combination of travel experience with remote work.',
          backLink: {
            url: '../camps.html?ref=back',
            text: 'Back'
          },
          nextLink: {
            url: 'macedonia.html?ref=next',
            text: ' Next month '
          },
          price: {
            value: '45$',
            unit: 'night',
            description: 'incl. utilities and internet'
          },
          intro: {
            url: 'https://go.remotecamp.net/NlbPLY',
            text: 'intro'
          },
          form: {
            checkIn: {
              title: 'check in',
              placeholder: '2/7/2021',
            },
            checkOut: {
              title: 'check in',
              placeholder: '2/7/2021',
            },
            dropdown: [
              {
                val: 'Private',
                legend: 'Private',
              },
              {
                val: 'Shared',
                legend: 'Shared',
              }
            ],
            submit: {
              url: "../form.html?camp=albania&price=700$",
              text: "Reserve",
              legend: "You won't be charged yet"
            },
          },
          paymentDetails: {
            currency: '$',
            totalText: 'Total',
            list: [
              {
                text: 'Accomondation',
                tooltip: 'Average nightly rate is rounded.',
                ariaLabel: 'Average nightly rate is rounded.',
                price: '630'
              },
              {
                text: '4% today program discount',
                tooltip: 'Only today special offer',
                ariaLabel: 'Only today special offer',
                price: '-30'
              },
              {
                text: 'Service fee',
                ariaLabel: 'Only today special offer',
                tooltip: 'This helps us run our platform and offer services like 24/7 support on your trip. It includes VAT.',
                price: '100'
              }
            ]
          }
        }
      },
      {
        type: 'faq',
        data: {
          title: 'Frequently Asked Questions',
          questions: [
            {
              title: 'What does the Cost include?',
              text: 'Your payment includes the rent of the villa with all the accommodations (your bed in private or double room and your workspace, the pool, etc.), payment for the Internet, fruits, tea and coffee. Also payment includes lectures, workshops, local entrepreneur meetings and all the joint activities that will take place on our villa.'
            },
            {
              title: 'The Cost does not include',
              text: 'Usually you buy plane tickets by yourself, but our team can help you with the choice, as well as provide great deals from our partners. Additional events and trips are paid by the participants on their own at will. Meals are also not included in the price and are organized either by each independently or jointly'
            },
            {
              title: 'Is it for me?',
              text: 'If you work remotely, want to travel, love nature, strive to make new friends, increase your productivity and learn new things - the answer is definitely YES!'
            },
            {
              title: 'Who we are',
              text: 'We are experienced remote workers and digital nomads who are ready to share our experience and teach you how to work from any part of the world, find work-live balance and not delay getting impressions on vacation.'
            },
            {
              title: 'Covid Policy',
              text: 'For the period of coronavirus, we choose open countries for camps. Some of them may require a visa or coronavirus test, which we will inform you about in advance. Each country has its own restrictive measures, we will inform the participants about them and we will follow them.'
            }
          ]
        }
      },
      {
        type: 'gallery',
        data: {
          title: 'Where',
          gallery: [
            {
              img: albania0,
              description: '🔊 Our villa has a quiet workplace and an open-space for activities.'
            },
            {
              img: albania1,
              description: '🌐 Your workspace will be comfortable, with a good chair and desk. There will be enough sockets for everyone.'
            },
            {
              img: albania2,
              description: '🔊 Our villa has a quiet workplace and an open-space for activities.'
            },
            {
              img: albania3,
              description: ''
            },
            {
              img: albania4,
              description: ''
            },
            {
              img: albania5,
              description: '📍 There are shops, cafes and everything you might need near the camp.'
            },
            {
              img: albania6,
              description: '🗺️ Remoteness from major roads, will provide us with clean mountain air, which will heal your body.'
            }
          ]
        }
      },
      {
        type: 'about',
        data: {
          title: 'Location',
          text: `
            From the stunning beaches of the Albanian Riviera to gorgeous archaeological sites, Albania will not disappoint you.
                  <br>
                  <br>
                  The Albanian Riviera is, with the capital city Tirana, the most popular place in the country. The reason is that it is just amazing: long sandy and pebble beaches lapped by turquoise water, and lovely coastal hamlets with small Orthodox churches, cobblestoned streets and flowers everywhere.
                   <br>
                   <br>
                  One of the most stunning destinations in Albania is Gjirokastër. This town is located in the south of the country and, for its unique Ottoman architecture, has been included in the heritage sites by UNESCO. 
                  <br>
                  <br>
                  One of the best reasons why everyone should include Albania in his or her bucket list’s places to see is the food. Albanian gastronomy is excellent, fresh and tasty. You can eat gorgeous dishes in the fanciest restaurants as well as amazing street foods.
          `,
          gallery: [
            {
              img: palmTree,
            },
            {
              img: albania1,
            },
            {
              text: '“I should probably get up–things to do.”',
            },
            {
              img: albania3,
            },
            {
              img: albania4,
            },
            {
              img: albania5,
            },
            {
              text: '“what ????”',
            },
            {
              img: albania6,
            },
            {
              img: laptop
            },
          ]
        }
      },
      {
        type: 'program',
        data: {
          title: "PROGRAM",
          img: program,
          text: `
             <p class="disappear"> In a new inspiring environment, among passionate people, and with the help of our program, which is specially designed for you, you can increase your productivity several times and regain your motivation for work and development of your projects!
                  <br>
                  <br>
                Our scrum master will help you with your goals, and additional events will help you to develop yourself in new interesting areas! There will be workshops, brainstorms and sharing of experience from participants, who are specialists in different fields of activities.
                <br>
                  <br>
               We will finalize a detailed daily schedule after we have interviews with each participant and understand your interests and ideas. At the same time, the program always remains flexible, participants can contribute and offer their event(s) for an evening
                Networking, workshops, immersive language learning environment, outdoor activities, reboot and inspiration.</p>
                <a href="https://bit.ly/34dg0q8" style="margin-top: 40px ; text-decoration: underline; font-weight: 600" >Check our articles about productivity 👀</a>
          `
        }
      }, {
        type: 'activities',
        data: {
          title: 'Activities',
          text: `
            <p style="text-align: left;margin-top: 80px" class="about_text">
                  We always have an idea for evening event. We can arrange a party and pool, wine tasting or a joint trip to the nearby ancient city on scooters. We will have  a list of what we can offer, but each participant is able to offer their own ideas, and we will plan them together!
                  <br>
                  <br>
                It's always fun to do something together. You can easily find your company among the community for windsurfing, fishing, jogging and anything else.
              </p>
                <a href="https://bit.ly/3yKIG7U" style="margin-top: 40px ; text-decoration: underline; font-weight: 600">Check our instagram with all photos 👀</a>
          `,
          img: spot1
        }
      },
      {
        type: 'date',
        data: {
          title: 'When',
          date: '22.07 - 06.08',
          text: `
            <p style="text-align: left;">The weather in Albania becomes more comfortable in June. The average daytime temperature throughout the country in June is +26°C, and at night it rarely drops less than +16°C. June is the best time to visit Vlorë, with her pleasant warm evenings.</p>

                <a href="https://bit.ly/3wvQGru" ><p style="margin: 40px 0px; text-decoration: underline; font-weight: 600">Check tickets for these dates ✈</p></a>
          `,
          applyLink: {
            url: `../form.html?camp=albania&price=700$`,
            text: 'Apply Now',
            icon: 'lni-arrow-down-circle',
          },
          calendar: {
            title: 'August 2021',
            daysLabels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
            dates: [
              [
                {
                  val: 30,
                  className: 'previous'
                },
                {
                  val: 31,
                  className: 'previous'
                },
                {
                  val: 1,
                },
                {
                  val: 2,
                },
                {
                  val: 3,
                },
                {
                  val: 4,
                },
                {
                  val: 5,
                },
              ],
              [
                {
                  val: 6,
                },
                {
                  val: 7,
                },
                {
                  val: 8,
                },
                {
                  val: 9,
                },
                {
                  val: 10,
                },
                {
                  val: 11,
                },
                {
                  val: 12,
                },
              ],
              [
                {
                  val: 13,
                },
                {
                  val: 14,
                },
                {
                  val: 15,
                },
                {
                  val: 16,
                },
                {
                  val: 17,
                },
                {
                  val: 18,
                },
                {
                  val: 19,
                },
              ],
              [
                {
                  val: 20,
                },
                {
                  val: 21,
                },
                {
                  val: 22,
                  className: 'active start'
                },
                {
                  val: 23,
                  className: 'active'
                },
                {
                  val: 24,
                  className: 'active'
                },
                {
                  val: 25,
                  className: 'active'
                },
                {
                  val: 26,
                  className: 'active'
                },
              ],
              [
                {
                  val: 27,
                  className: 'active'
                },
                {
                  val: 28,
                  className: 'active'
                },
                {
                  val: 29,
                  className: 'active'
                },
                {
                  val: 30,
                  className: 'active'
                },
                {
                  val: 31,
                  className: 'active'
                },
                {
                  val: 1,
                  className: 'next'
                },
                {
                  val: 2,
                  className: 'next'
                },
              ]
            ]
          }
        }
      },
      {
        type: 'learnMore',
        data: {
          title: 'Do you want to learn more about our camps?',
          followers: {
            title: 'followers',
            count: '1300'
          },
          posts: {
            title: 'posts',
            count: '200'
          }
        }
      },
      {
        type: 'bottomMenu',
        data: {
         link: {
           text: 'Apply',
           url: '../form.html?camp=albania&price=500$'
         },
         list: [
           {
             icon: 'wallet',
             title: 'Price',
             text: 'from 35$ per night',
             link: 'cost',
             withNotification: true
           },
           {
             icon: 'pin',
             title: 'Place',
             text: 'Villa',
             link: 'where'
           },
           {
             icon: 'library',
             title: 'Program',
             text: 'Program',
             link: 'program'
           },
           {
             icon: 'surf-board',
             title: 'Activities',
             text: 'Activities',
             link: 'activity'
           },
           {
             icon: 'calendar',
             title: 'Date',
             text: '22.07 - 06.08',
             link: 'when'
           }
         ]
        }
      }
    ]
  }
}

export {
  CAMPS
}
