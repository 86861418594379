import { FaqItem } from './FaqItem'

const Faq = ({questions, title}) => {
  return (
    <section className="white-section container" id="faq">
      <div className="row">
        <div className="col-md-12">
          {title && (
            <h2>Frequently Asked Questions</h2>
          )}
          <div className="accordion">
            {questions.map((question, i) => {
              return (
                <FaqItem {...question} key={i} />
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export {
  Faq
}
