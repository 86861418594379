const Activities = ({title, text, img}) => {
  return (
    <section className="white-section container" id="activity">
      {title && (
        <div className="black_row row">
          <div className="col-sm-12">
            <div className="row">
              <div className="col-lg-12">
                <h2>{title}</h2>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="row">
        {text && (
          <div className="col-lg-6">
            <div dangerouslySetInnerHTML={{__html: text}} />
          </div>
        )}
        { img && (
          <div className="col-lg-6">
            <img src={img} alt=""/>
          </div>
        )
        }
      </div>

    </section>
  )
}

export {
  Activities
}
