import {Link} from "react-router-dom";
import style from './styles.module.css'

const CampCard = ({
  controlContent,
  images,
  city,
  campText,
  date,
  link,
  price,
  active,
  highlight
}) => {
  return (
    <div className={`feature-area ${style['feature-area']}`}>
      <div className={`card_panel ${style['card_panel']} ${active ? `${style['card_panel--active']}` : ''} ${highlight ? `${style['card_panel--highlight']}` : ''} `}>
        <label htmlFor="3" className={`control ${style['control']}`}>
          <input type="checkbox" name="topics" id="3" checked />
          <span className={`control__content ${style['control__content']}`}>
                        <svg aria-hidden="true" focusable="false" width="30" height="30"
                             viewBox="0 0 30 30" fill="none" >
                        <circle cx="15" cy="15" r="15" fill="#1E1B1D" /><path
                          d="M10.78 21h1.73l.73-3.2h2.24l-.74 3.2h1.76l.72-3.2h3.3v-1.6H17.6l.54-2.4H21v-1.6h-2.5l.72-3.2h-1.73l-.73 3.2h-2.24l.74-3.2H13.5l-.73 3.2H9.5v1.6h2.93l-.56 2.4H9v1.6h2.52l-.74 3.2zm2.83-4.8l.54-2.4h2.24l-.54 2.4H13.6z"
                          fill="#fff" /></svg>
            {controlContent}
                      </span>
        </label>
        <div className={`slider-wrapper slider-wrapper2 ${style['slider-wrapper']}`}>
          <div className="slider slider2">
            {images?.length > 0 && images.map((image, i) => {
              return (
                <div className="slide slide2" key={i}>
                  <div className={`image_camp ${style['image_camp']}`} >
                    <img src={image} alt=""/>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <span className="city_name">{city}</span>
        <div className={`camp_text ${style['camp_text-wrap']}`}>
          <span className={`${style['camp_text']}`}>{campText}</span>
          <h6 className={`${style['camp_date']}`}>
            {date}
          </h6>
          <div className={`${style['header-btn']}`}>
            <a className={`${style['btn-hover']}`} target="_blank" href={link}>Explore </a>
            <span className={`${style['header-price']}`}>$ <span className={`${style['header-cost']}`}>{price}</span> per day</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export {
  CampCard
}
