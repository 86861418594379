import style from './style.module.css'
import img from '../../../assets/img/intro6.svg'

const About = ({text}) => {
  return (
    <section className={`white-section container ${style['wrap']}`}>

      <div className={`row ${style['title-wrap']}`}>
        <h2 className={style.title}>What is Remotecamp ?</h2>
      </div>
      <div className={`row ${style['text-wrap']}`}>
        <div className="col-lg-6">
          <div dangerouslySetInnerHTML={{__html: text}}/>
        </div>
        <div className={`col-lg-6 order-first order-md-last ${style['wi-fi-wrap']}`}>
          <div className={`hero-img wow fadeInRight hidden ${style['wi-fi-wrap-content']}`}>
            <div className={`wifi-symbol ${style['wi-fi-symbol']}`}>
              <div className="wifi-circle first" />
              <div className="wifi-circle second" />
              <div className="wifi-circle third" />
              <div className="wifi-circle fourth" />
            </div>
            <img src={img} alt="nomad character" className="character" />
          </div>
        </div>
      </div>
    </section>
  )
}

export {
  About
}
