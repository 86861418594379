const ROUTES_CONFIG = {
  about: {
    route: '/about'
  },
  blog: {
    route: '/blog'
  },
  book: {
    route: '/book'
  },
  camp: {
    route: '/camps'
  },
  campDetail: {
    route: '/camps/:id'
  },
  form: {
    route: '/form'
  },
  gallery: {
    route: '/gallery'
  },
  howItWorks: {
    route: '/howItWorks'
  },
  partnersForm: {
    route: '/partnersForm'
  },
  partners: {
    route: '/partners'
  },
  previous: {
    route: '/previous'
  },
  privacy: {
    route: '/privacy'
  },
  subscribe: {
    route: '/subscribe'
  },
  terms: {
    route: '/terms'
  },
  test: {
    route: '/test'
  },
  unsubscribe: {
    route: '/unsubscribe'
  },
}

export {
  ROUTES_CONFIG
}
