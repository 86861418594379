import style from './style.module.css'

import img1 from '../../../assets/img/media1.png'
import img2 from '../../../assets/img/media2.png'
import img3 from '../../../assets/img/media3.png'

const Logos = ({title}) => {
  return (
    <section className="white-section container">
      <h2 className={style.title}>{title}</h2>
      <div className={`row ${style.content}`} >
        <div className="col-lg-2 col-md-2 col-xs-6">
          <a target="_blank" href="https://mrs-anosempai.medium.com/a6b3a977921c"><img src={img1} alt="digital nomad articles"  className="logos" /></a>
        </div>
        <div className="col-lg-2 col-md-2 col-xs-6">
          <a target="_blank" href="https://www.producthunt.com/posts/remotecamp"><img src={img2} alt="digital nomad articles" className="logos" /></a>
        </div>
        <div className="col-lg-2 col-md-2 col-xs-6">
          <a target="_blank" href="https://vc.ru/life/235934-udalennaya-rabota-na-novom-urovne-kak-my-pereosmyslili-staryy-format">
            <img src={img3} alt="digital nomad articles"  className="logos" />
            </a>
        </div>        
        <div className="col-lg-2 col-md-2 col-xs-6">
          <a target="_blank" href="https://vc.ru/life/235934-udalennaya-rabota-na-novom-urovne-kak-my-pereosmyslili-staryy-format">
            <img src={img3} alt="digital nomad articles"  className="logos" />
            </a>
        </div>        
        <div className="col-lg-2 col-md-2 col-xs-6">
          <a target="_blank" href="https://vc.ru/life/235934-udalennaya-rabota-na-novom-urovne-kak-my-pereosmyslili-staryy-format">
            <img src={img3} alt="digital nomad articles"  className="logos" />
            </a>
        </div>        
        <div className="col-lg-2 col-md-2 col-xs-6">
          <a target="_blank" href="https://vc.ru/life/235934-udalennaya-rabota-na-novom-urovne-kak-my-pereosmyslili-staryy-format">
            <img src={img3} alt="digital nomad articles"  className="logos" />
            </a>
        </div>
      </div>
    </section>
  )
}

export {
  Logos
}
