import style from './style.module.css'

const LearnMore = ({title, followers, posts}) => {
  return (
    <section className="white-section container call2action">
      <div className={`row ${style['row']}`}>
        <div className={`${style['instagram-wrap']} col-md-1 hidden`}>
          <div className={style['instagram']}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="#fff"><path d="M12 2.2c3.2 0 3.6 0 4.8.1 1.2.1 1.8.2 2.2.4.6.2 1 .5 1.4.9.4.4.7.8.9 1.4.2.4.4 1.1.4 2.2.1 1.3.1 1.6.1 4.8s0 3.6-.1 4.8c-.1 1.2-.2 1.8-.4 2.2-.2.6-.5 1-.9 1.4-.4.4-.8.7-1.4.9-.4.2-1.1.4-2.2.4-1.3.1-1.6.1-4.8.1s-3.6 0-4.8-.1c-1.2-.1-1.8-.2-2.2-.4-.6-.2-1-.5-1.4-.9-.4-.4-.7-.8-.9-1.4-.2-.4-.4-1.1-.4-2.2-.1-1.3-.1-1.6-.1-4.8s0-3.6.1-4.8c0-1.2.2-1.9.3-2.3.2-.6.5-1 .9-1.4.5-.4.9-.6 1.4-.9.4-.1 1.1-.3 2.3-.4H12M12 0C8.7 0 8.3 0 7.1.1c-1.3 0-2.2.2-3 .5C3.4.9 2.7 1.3 2 2 1.3 2.7.9 3.4.6 4.1c-.3.8-.5 1.7-.5 3C0 8.3 0 8.7 0 12c0 3.3 0 3.7.1 4.9.1 1.3.3 2.1.6 2.9.2.8.6 1.5 1.3 2.2.7.7 1.3 1.1 2.1 1.4.8.3 1.6.5 2.9.6h5s3.7 0 4.9-.1c1.3-.1 2.1-.3 2.9-.6.8-.3 1.5-.7 2.1-1.4.7-.7 1.1-1.3 1.4-2.1.3-.8.5-1.6.6-2.9.1-1.2.1-1.6.1-4.9s0-3.7-.1-4.9c-.1-1.3-.3-2.1-.6-2.9-.2-.8-.6-1.5-1.3-2.2-.7-.7-1.3-1.1-2.1-1.4C19.1.3 18.3.1 17 0h-5z"/><path d="M12 5.8c-3.4 0-6.2 2.8-6.2 6.2s2.8 6.2 6.2 6.2 6.2-2.8 6.2-6.2-2.8-6.2-6.2-6.2zM12 16c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4z"/><circle cx="18.4" cy="5.6" r="1.4"/></g></svg>
          </div>
        </div>
        <div className={`col-md-5 col-12 ${style['details']}`}>
          <h4>{title}</h4>
        </div>
        <div className={`col-md-2 col-6 ${style['block-wrap']}`}>
          <a href="https://go.remotecamp.net/Px98R5">
            <div className={`btn-hover ${style['instagram-btn']}`}>
              <i className="lni lni-instagram hidden" />
              Instagram</div>
          </a>
        </div>
        {followers && (
          <div className={`col-md-2 col-6 ${style['block-wrap']}`}>
            <p>{followers.title}</p>
            <span>{followers.count}+</span>
          </div>
        )}
        {posts && (
          <div className={`col-md-2 col-3 ${style['block-large-wrap']}`}>
            <p>{posts.title}</p>
            <span>{posts.count}+</span>
          </div>
        )}
      </div>
</section>
  )
}

export {
  LearnMore
}
