import { CampCard } from '../CampCard'
import { TimeToTravelCard } from '../TimeToTravelCard'
import { PollCard } from '../PollCard'

import style from './style.module.css'

const Cards = ({
  title,
  link,
  cards
}) => {

return (
  <section className="white-section container">
    <div className="row">
      {title && (
        <h6 className="col-6">{title}</h6>
      )}
      {link && (
        <div className={style['link-wrap']}>
          <a href={link.url} className="col-6">{link.title}</a>
        </div>
      )}
    </div>
    {cards?.length > 0 && (
      <div className="wrap">
        <div className={style['camp-list']}>
          {cards.map((card, i) => {

            if (card.type === 'timeToTravel') {
              return (
                <div className={`each_camp ${style['each_camp']}`} key={i}>
                  <TimeToTravelCard {...card} key={i}/>
                </div>
              )
            }

            if (card.type === 'pollCard') {
              return (
                <div className={`each_camp ${style['each_camp']}`} key={i}>
                  <PollCard {...card} key={i}/>
                </div>
              )
            }

            if (card.type) {
              return null
            }

            return (
              <div className={`each_camp ${style['each_camp']}`} key={i}> <CampCard {...card}/></div>
            )
          })}
        </div>
      </div>
    )}
  </section>
      )
}

export {
  Cards
}
