import { useState } from "react";
import style from './style.module.css'

const FaqItem = ({title, text}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className={`accordion-item ${style['accordion-item']}`}>
      <button type="button" onClick={() => setIsExpanded(!isExpanded)} aria-expanded={isExpanded ? 'true' : 'false'}>
        <span className="accordion-title">{title}</span>
        <span className="icon" aria-hidden={isExpanded ? 'true' : 'false'} />
      </button>
      <div className="accordion-content">
        <p>
          {text}
        </p>
      </div>
    </div>
  )
}

export {
  FaqItem
}
