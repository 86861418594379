import style from './style.module.css'
import video from '../../../assets/img/video/new.mp4'
import {Fragment} from "react";

const Hero = ({
                title,
                tooltip,
                closestCampTitle,
                closestCamps,
                features
              }) => {
  return (
    <div className={style.wrap}>
      <div className={`overlay ${style.overlay}`}>
      </div>

      <video id="video2" preload className={style.video} autoPlay muted playsInline loop>
        <source src={video} type="video/mp4" />
      </video>


      <section id="home" className={`hero-area ${style['hero-area']}`}>

        <div className="intro container">
          <div className={`row align-items-center ${style['hero-content-wrap']}`} >
            <div className="col-xl-7 col-lg-7">
              <div className="hero-content">
                <h1 className={style['hero-h1']}>
                  {title}
                </h1>

                <div className="input-group">
                  <span className="input-group-btn">
                  <a href="en/camps.html" target="_blank" type="button" className={`btn btn-primary ${style['camps-link']}`} id="exampleButton1">
                    <span className="btn-text">Explore <span className="hidden btn-text">locations</span></span>
                  </a>
                  <a href="en/form.html?price=500$" target="_blank" type="button" className={`btn btn-primary ${style['form-link']}`} id="exampleButton1">
                    <span className="btn-text">Get started</span>
                  </a>
                </span>
                  <div className={`hidden ${style['links-wrap']}`}>
                    {closestCampTitle && (
                      <span className={`${style['link-item']} ${style['link-item--bold']}`}>
                          {closestCampTitle}:
                        </span>
                    )}
                    {closestCamps?.length > 0 && (
                      <>
                        {closestCamps.map((camp) => {
                          return (
                            <a href={camp.link} target="_blank" key={camp.title}><span
                              className={`${style['link-item']}`}>
                              {camp.title}
                            </span></a>
                          )
                        })}
                      </>
                    )}
                  </div>
                </div>
                {features?.length > 0 && (
                  <p className={`wow fadeInUp certain_text ${style['features-list']}`}>
                    {features.map((feature, i) => {
                      return (
                        <Fragment key={i}>
                          <div className={style['features-list-item']} dangerouslySetInnerHTML={{__html: feature}} />
                        </Fragment>
                      )
                    })}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}


export {
    Hero
}
